import { navigate } from 'gatsby'
import React, { BaseSyntheticEvent, useState } from 'react'

// Utils
import { createPath } from '../../../utils/utils'

// Models
import { ComicModel } from '../../../models'

// Icons
import NoCover from '../../../assets/icons/NoCover.png'

// Style
import {
  cardInfo,
  cardName,
  cardExtra,
  cardCover,
  cardNoCover,
  cardWrapper,
  cardContainer,
} from './style.module.scss'

interface Props {
  link: string
  title: string
  image?: string
  description?: string
}

const SimpleCard: React.FC<Props> = ({ title, description, link, image }) => {
  const [coverStyle, setCoverStyle] = useState(cardCover)
  return (
    <div
      className={cardContainer}
      onClick={() => {
        navigate(link)
      }}
    >
      <div className={cardWrapper}>
        <img
          className={coverStyle}
          src={image ?? ''}
          onError={(e: BaseSyntheticEvent) => {
            e.target.src = NoCover
            setCoverStyle(cardNoCover)
          }}
          loading="lazy"
        />
        <div className={cardInfo}>
          <div className={cardName}>{title}</div>
          <div className={cardExtra}>{description}</div>
        </div>
      </div>
    </div>
  )
}

export default SimpleCard
